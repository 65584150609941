/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  pointer-events: none;
}

.zoom-in {
  animation: zoom-in 0.6s ease;
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(30, 138, 132); */
}

.bg-green-100 {
  background-color: #d1fae5 !important;
}

.bg-opacity-60 {
  --tw-bg-opacity: 0.6 !important;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5 !important;
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4 !important;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(200, 200, 200);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(83, 171, 243);
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece:hover {
  background: rgb(161, 213, 255);
  border-radius: 10px;
}

.shadow-xl-custom {
  --tw-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.watermark {
  position: relative;
  width: 100%;
  height: 100%;
}

.watermark::after {
  content: "VERIFIED TRANSCRIPT";
  font-size: 2.4rem;
  color: #2cbe811f;
  /* Adjust opacity as needed */
  position: absolute;
  top: 62%;
  left: 42%;
  transform: translate(-30%, -140%) rotate(-30deg);
  z-index: 0.4;
  /* Ensure the watermark is behind other content */
  pointer-events: none;
  /* Make the watermark unclickable */
}

/* Media query for mobile phone */
@media only screen and (max-width: 768px) {
  .watermark::after {
    font-size: 1.5rem;
    /* Adjust font size for smaller screens */
    top: 40%;
    /* Adjust vertical position */
    left: 19%;
    /* Adjust horizontal position */
    transform: translate(10%, 350%) rotate(-30deg);
    /* Adjust positioning */
  }
}